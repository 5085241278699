<template>
  <div class="box">
    <div class="contion">
      <div class="title">联系我们</div>
      <div class="text">联系方式：{{ addressObj.contactNumber }}</div>
      <div class="text">联系地址：{{ addressObj.address }}</div>
      <div id="container" class="map"></div>
      <div class="infoWindow" ref="infoWindow" style="display: none;">
        <div class="info-title">{{addressObj.school_name}}</div>
        <div class="info-content">
          <div class="">地址：{{ addressObj.address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
export default {
  props: {
    addressObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {
    // this.loadmap(); //加载地图和相关组件
    console.log(this.addressObj,'打印新的信息')
  },
  methods: {
    loadmap() {
      let _this = this;
      console.log(_this.addressObj.lat,_this.addressObj.long,'打印当前使用的经纬度')
      let lnglat = [Number(_this.addressObj.long),Number(_this.addressObj.lat)];
      var map = new AMap.Map("container", {
        center: lnglat,
        resizeEnable: true,
        zoom: 15,
      });
      // 添加定位标记
      let marker = new AMap.Marker({
        position: map.getCenter(),
      });
      marker.setMap(map);
      AMap.plugin(
        ["AMap.ToolBar", "AMap.Scale", "AMap.AdvancedInfoWindow"],
        function () {
          map.addControl(new AMap.ToolBar());
          map.addControl(new AMap.Scale());
          var advancedInfoWindow = new AMap.AdvancedInfoWindow({
            // panel: "panel",
            placeSearch: true,
            asOrigin: true,
            asDestination: true,
            content: _this.$refs.infoWindow.innerHTML,
          });
          // 打开信息窗体
          advancedInfoWindow.open(map, map.getCenter());
        }
      );
    },
  },
  watch: {
    addressObj() {
      console.log('fgfgfg')
      this.loadmap()
    },
  },
};
</script>

<style scoped lang="less">
// 高德地图信息窗
.info-title {
  color: #000;
  font-size: 16px;
  line-height: 26px;
  padding: 6px 8px;
  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 1px solid #e4e4e4;
}
.info-content {
  font: 14px Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", Arial;
  padding: 4px 8px;
  color: #666666;
  line-height: 23px;
}

.box {
  width: 100%;
  margin-bottom: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .contion {
    background: #fff;
    width: 1200px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      padding: 7px 0;
      border-bottom: 1px solid #eeeeee;
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      color: #097638;
    }
    .map {
      width: 100%;
      height: 490px;
      margin-top: 25px;
    }
    .text {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }
    .swiper {
      width: 100%;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      .left {
        height: 300px;
        width: 342px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 6px rgba(0, 59, 8, 0.08);
        .img {
          img {
            width: 342px;
            height: 228px;
            background: #ffffff;
            border-radius: 4px 4px 0px 0px;
          }
        }
        .leftText {
          height: 72px;
          width: 100%;
          padding: 10px 63px 10px 20px;
          color: #333333;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .showTitle {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .botTitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .leftWiText {
              width: 145px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .rightTit {
              color: #999999;
            }
          }
        }
      }
      .centera {
        height: 407px;
        width: 509px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 6px rgba(0, 59, 8, 0.08);
        .img {
          img {
            width: 509px;
            height: 339px;
            border-radius: 4px 4px 0px 0px;
          }
        }
        .centerText {
          height: 68px;
          width: 100%;
          padding: 0 20px;
          color: #333333;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .leftTitle {
            width: 175px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .centerTime {
            color: #999;
          }
        }
      }
    }
    .toSome {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #097638;
      cursor: pointer;
    }
  }
}
</style>