<!--
 * @Author: Damon
 * @Description: 
 * @Date: 2020-10-29 17:38:34
 * @LastEditors: Please set LastEditors
-->
<template>
<div>
  <common-header :current-index="2"></common-header>
  <aboutUs></aboutUs>
  <common-footer></common-footer>
</div>
  
</template>

<script>
import commonHeader from '@/components/commonHeader'
import aboutUs from '@/components/about/aboutUs'
import commonFooter from '@/components/commonFooter'
export default {
  components: {
    commonHeader,aboutUs,commonFooter
  }
}
</script>

<style>

</style>
