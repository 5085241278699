<template>
  <div class="box">
    <div class="contion">
      <div class="title">用户协议</div>
      <div class="text" v-html="domin.content">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    domin:{
      type:Object,
      default:()=>{}
    }
  }
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom:38px;
  align-items: center;
  .contion {
    background: #fff;
    width: 1200px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
        padding:7px 0;
border-bottom: 1px solid #EEEEEE;
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      color: #097638;
    }
    .text {
        margin-top:30px;
        text-indent: 2rem;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }
    .swiper {
      width: 100%;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      .left {
        height: 300px;
        width: 342px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 6px rgba(0, 59, 8, 0.08);
        .img {
          img {
            width: 342px;
            height: 228px;
            background: #ffffff;
            border-radius: 4px 4px 0px 0px;
          }
        }
        .leftText {
          height: 72px;
          width: 100%;
          padding: 10px 63px 10px 20px;
          color: #333333;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .showTitle {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .botTitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .leftWiText {
              width: 145px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .rightTit {
              color: #999999;
            }
          }
        }
      }
      .centera {
        height: 407px;
        width: 509px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 6px rgba(0, 59, 8, 0.08);
        .img {
          img {
            width: 509px;
            height: 339px;
            border-radius: 4px 4px 0px 0px;
          }
        }
        .centerText {
          height: 68px;
          width: 100%;
          padding: 0 20px;
          color: #333333;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .leftTitle {
            width: 175px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .centerTime {
            color: #999;
          }
        }
      }
    }
    .toSome {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #097638;
      cursor: pointer;
    }
  }
}
</style>