<template>
  <div class="boxa">
    <div class="list">
      <div class="tabBox">
        <div class="tab">
          <div class="right">
            <div
              :class="currentIndex === index ? 'active' : ''"
              v-for="(item, index) in tabbar"
              :key="index"
              @click="tabbarItem(index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <schoolIntroduce :domin="domin" v-show="currentIndex == 0" ></schoolIntroduce>
      <contactUs :addressObj="addressObj" v-show="currentIndex == 1" ></contactUs>
      <privacyPolice :domin="domin" v-show="currentIndex == 2"></privacyPolice>
      <userAgreement :domin="domin" v-show="currentIndex == 3"></userAgreement>
    </div>
  </div>
</template>

<script>
import { getDataAbout, getContactUs } from "@/common/public.js";
import schoolIntroduce from "@/components/about/schoolIntroduce";
import contactUs from "@/components/about/contactUs";
import privacyPolice from "@/components/about/privacyPolice";
import userAgreement from "@/components/about/userAgreement";
export default {
  components: {
    schoolIntroduce,
    contactUs,
    privacyPolice,
    userAgreement,
  },
  data() {
    return {
      tabbar: ["学校简介", "联系我们", "隐私政策 ", "用户协议"],
      currentIndex: 0,
      codeName: "profile",
      domin: {}, //关于我们信息对象
      addressObj: {} //地址信息对象
    };
  },
  created() {
    this.getTabbarStr();
  },
  methods: {
    //切换tabbar
    tabbarItem(index) {
      this.currentIndex = index;
      if (index == 1) {
        this.getContactUs();
      } else {
        if (index == 0) {
          this.codeName = "profile";
          this.getTabbarStr();
        } else if (index == 2) {
          this.codeName = "secret";
          this.getTabbarStr();
        } else if (index == 3) {
          this.codeName = "agreement";
          this.getTabbarStr();
        } else {
          console.log(" ");
        }
      }
    },
    //根据tabbar获取信息
    getTabbarStr() {
      let data = {
        codeName: this.codeName,
      };
      getDataAbout(data).then((res) => {
        this.domin = res.result.domain;
      })
    },
    //联系我们接口
    getContactUs() {
      getContactUs().then((res) => {
        console.log(res,'打印传递的信息')
        this.addressObj = res.result;
      })
    }
  }
}
</script>

<style scoped lang="less">
.boxa {
  width: 100%;
  display: flex;
  background: #f1f1f1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .tabBox {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .tab {
        width: 1200px;
        height: 56px;
        margin: 30px 0 20px 0;
        display: flex;
        align-items: center;
        background: #fff;
        padding: 0 20px;
        .right {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          color: #707070;
          div {
            cursor: pointer;
            color: #333333;
            margin-right: 45px;
          }
          .active {
            color: #097638;
          }
        }
      }
    }
  }
}
</style>