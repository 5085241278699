<template>
  <div class="box">
    <div class="contion">
      <div class="title">学校简介</div>
      <div class="text" v-html="domin.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    domin: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 38px;
  justify-content: center;
  align-items: center;
  .contion {
    background: #fff;
    width: 1200px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      padding: 7px 0;
      border-bottom: 1px solid #eeeeee;
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      color: #097638;
    }
    .text {
      margin-top: 30px;
      text-indent: 2rem;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }
  }
}
</style>